<template>
    <v-container fluid>
        <v-card class="header-card">
            <v-card-title class="card-title">Articles</v-card-title>
            <div class="header-buttons">
                <v-btn class="my-2 mr-2" dark small color="black" @click="createArticle">
                    <v-icon dark>mdi-plus</v-icon>Create
                </v-btn>
            </div>
        </v-card>
        <array-view
            :items="articles"
            :headers="displayInfo"
            :total-pages="totalPages"
            :page="page"
            :size="size"
            @onDelete="onDelete"
            @handleClick="handleClick"
            @paginationChange="paginationChange"
        />
    </v-container>
</template>

<script>
    import articleService from '@/services/article'
    import ArrayView from '@/components/ArrayView'

    export default {
        name: 'Article',
        components: { ArrayView },
        data: () => {
            return {
                successfullImport: null,
                articles: [],
                displayInfo: [
                    {
                        text: 'Id',
                        value: 'id'
                    },
                    {
                        text: 'Title',
                        value: 'title'
                    },
                    {
                        text: 'Date',
                        value: 'date'
                    }
                ],
                showImportDialog: false,
                size: 5,
                totalPages: 0,
                page: 1
            }
        },
        created() {
            this.page = this.$store.getters.getPage
            this.fetchAllArticles()
        },
        methods: {
            paginationChange(page, size) {
                this.size = size
                this.page = page
                this.$store.dispatch('setPage', page)
                this.fetchAllArticles()
            },
            handleClick(action, item) {
                this.$router.push(`/articles/${item.id}/${action}`)
            },
            createArticle() {
                this.$router.push(`/articles/create`)
            },
            fetchAllArticles() {
                articleService.getArticles(this.page, this.size).then(resp => {
                    this.articles = resp.data.content
                    this.totalPages = resp.data.totalPages
                })
            },
            onDelete(article) {
                articleService.delete(article.id).then(resp => {
                    this.fetchAllArticles()
                })
            }
        }
    }
</script>

<style scoped>
.card-title {
    display: flex;
}

.v-list-item {
    min-height: 30px !important;
}

.v-card {
    min-width: 250px;
    position: relative;
}

.header-card {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1%;
}

.header-buttons {
    align-self: center;
}

.edit-button:hover {
    background-color: whitesmoke;
    color: white;
}

.screen-card {
    padding: 4%;
}

.overflow-text {
    max-width: 79%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.pagination {
    justify-content: center;
}
</style>
