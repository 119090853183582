import api from '@/utils/api'

export default {
    getArticles(page, size) {
        let range = '?range=' + encodeURIComponent(`[${page - 1},${size}]`)
        return api.get(`articles` + (page ? range : ''))
    },

    getArticle(id) {
        return api.get(`articles/${id}`)
    },

    createArticle(data) {
        return api.post('articles', data)
    },

    updateArticle(id, data) {
        return api.put(`articles/${id}`, data)
    },

    delete(id) {
        return api.delete(`articles/${id}`)
    },

    uploadFile(formData) {
        return api.uploadFile('uploads/articles', formData)
    }
}
